import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const routes = [
    {
        path: '/tendersure/auction/buyers',
        name: 'TendersureAuctionBuyers',
        component: () => import('../../views/qed/auction/Buyers.vue')
    },
    {
        path: '/tendersure/auction/list',
        name: 'TendersureAuctionList',
        component: () => import('../../views/qed/auction/CompanyAuctionList.vue')
    },
    {
        path: '/tendersure/add/auction',
        name: 'TendersureAddAuction',
        component: () => import('../../views/qed/auction/AddAuction.vue')
    },
    {
        path: '/tendersure/edit/auction/:id',
        name: 'TendersureEditAuction',
        component: () => import('../../views/qed/auction/EditAuction.vue')
    },
    {
        path: '/tendersure/:auctionId/auction',
        name: 'TendersureAuctionDetails',
        component: () => import('../../views/qed/auction/AuctionDetails.vue')
    },
    // Invited Participants
    {
        path: '/tendersure/invited/suppliers/:auctionId/auction',
        name: 'TendersureInvitedParticipantsAuction',
        component: () => import('../../views/qed/auction/invitees/Invitees.vue')
    },

    // Add Item
    {
        path: '/tendersure/:auctionId/auction/add/item',
        name: 'TendersureAuctionAddItem',
        component: () => import('../../views/qed/auction/item/AddItem.vue')
    },

    {
        path: '/tendersure/:auctionId/:itemId/auction/edit/item',
        name: 'TendersureAuctionAddItem',
        component: () => import('../../views/qed/auction/item/EditItem.vue')
    },
    {
        path: '/tendersure/item/:auctionId/:itemId/auction',
        name: 'TendersureItemDetailsAuction',
        component: () => import('../../views/qed/auction/item/DetailsItem.vue')
    },

    // Download Progress Auction Page
    {
        path: '/tendersure/auction/download/progress/:task_id',
        name: 'TendersureAuctionDownloadProgress',
        component: () => import('../../views/qed/auction/DownloadProgress.vue')
    },

    // Uplaod Multiple Images
    {
        path: '/uploadmultiple',
        name: 'uploadmultiple',
        component: () => import('../../views/qed/auction/uploadmultiple')
    },

    //technical
    {
        path: '/tendersure/auction/:auctionId/technical',
        name: 'TendersureDetailsAuctionTechnical',
        component: () => import('../../views/qed/auction/TechnicalDetails.vue')
    },
    {
        path: '/tendersure/auction/create-section/:auctionId',
        name: 'TendersureAuctionSectionCreate',
        component: () => import('../../views/qed/auction/section/Create.vue')
    },
    {
        path: '/tendersure/auction/edit-section/:auctionId/:sectionId',
        name: 'TendersureAuctionSectionEdit',
        component: () => import('../../views/qed/auction/section/Edit.vue')
    },
    {
        path: '/tendersure/auction/section/questions/:auctionId/:sectionId',
        name: 'TendersureAuctionSectionQuestions',
        component: () => import('../../views/qed/auction/section/Questions.vue')
    },
    {
        path: '/tendersure/auction/create-question/:auctionId/:sectionId',
        name: 'TendersureAuctionQuestionCreate',
        component: () => import('../../views/qed/auction/section/CreateQuestion.vue')
    },
    {
        path: '/tendersure/auction/edit-question/:auctionId/:sectionId/:questionId',
        name: 'TendersureAuctionQuestionEdit',
        component: () => import('../../views/qed/auction/section/EditQuestion.vue')
    },
    {
        path: '/tendersure/auction/question-upload-progress/:auctionId/:taskId',
        name: 'TendersureAuctionQuestionUploadProgress',
        component: () => import('../../views/qed/auction/section/QuestionUploadProgress.vue')
    },
    {
        path: '/tendersure/auction/reports/:auctionId',
        name: 'TendersureAuctionReports',
        component: () => import('../../views/qed/auction/reports/Details.vue')
    },
    {
        path: '/tendersure/auction/report/progress/:taskId',
        name: 'TendersureAuctionReportProgress',
        component: () => import('../../views/qed/auction/reports/Progress.vue')
    },
]

export default routes;