const routes = [
    //Suppliers Auction
  {
    path: '/supplier/list/reverse/auction',
    name: 'SupplierDetailsReverseAuction',
    component: () => import('../../views/supplier/auction/reverse_auctions/List.vue')
  },
  {
    path: '/supplier/list/forward/auction',
    name: 'SupplierDetailsForwardAuction',
    component: () => import('../../views/supplier/auction/foward_auctions/List.vue')
  },
  {
    path: '/supplier/:auctionId/forward/auction',
    name: 'SupplierParticipateForwardAuction',
    component: () => import('../../views/supplier/auction/foward_auctions/Participate.vue')
  },
  {
    path: '/supplier/:auctionId/reverse/auction',
    name: 'SupplierParticipateReverseAuction',
    component: () => import('../../views/supplier/auction/reverse_auctions/Participate.vue')
  },
  {
    path: '/supplier/bid/:auctionId/forward/auction',
    name: 'SupplierBidForwardAuction',
    component: () => import('../../views/supplier/auction/foward_auctions/Bid.vue')
  },
    {
    path: '/supplier/bid/technical/:auctionId/forward/auction',
    name: 'SupplierBidForwardAuction',
    component: () => import('../../views/supplier/auction/foward_auctions/TechnicalBid.vue')
  },
    {
    path: '/supplier/bid/technical/preview/:auctionId/forward/auction',
    name: 'SupplierBidForwardAuctionQuestionPreview',
    component: () => import('../../views/supplier/auction/foward_auctions/QuestionPreview.vue')
  },
  // Test New Day
  {
    path: '/supplier/bid/:auctionId/test/auction',
    name: 'SupplierBidTestAuction',
    component: () => import('../../views/supplier/auction/reverse_auctions/Bid_Auction.vue')
  },
  {
    path: '/supplier/bid/:auctionId/reverse/auction',
    name: 'SupplierBidReverseAuction',
    component: () => import('../../views/supplier/auction/reverse_auctions/Bid.vue')
  },
    {
    path: '/supplier/bid/technical/:auctionId/reverse/auction',
    name: 'SupplierTechnicalBidReverseAuction',
    component: () => import('../../views/supplier/auction/reverse_auctions/TechnicalBid.vue')
  },
  {
    path: '/supplier/bid/technical/preview/:auctionId/reverse/auction',
    name: 'SupplierBidReverseAuctionQuestionPreview',
    component: () => import('../../views/supplier/auction/reverse_auctions/QuestionPreview.vue')
  },
]

export default routes;