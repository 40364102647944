import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const routes = [
    // List Auction
    {
        path: '/buyer/list/forward/auction',
        name: 'BuyerListForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/List.vue')
    },
    {
        path: '/buyer/list/reverse/auction',
        name: 'BuyerListReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/List.vue')
    },

    // Add Auction
    {
        path: '/buyer/add/forward/auction',
        name: 'BuyerAddForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/AddAuction.vue')
    },
    {
        path: '/buyer/add/reverse/auction',
        name: 'BuyerAddReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/AddAuction.vue')
    },

    // Edit Auction
    {
        path: '/buyer/edit/:auctionId/reverse/auction',
        name: 'BuyerEditReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/EditAuction.vue')
    },
    {
        path: '/buyer/edit/:auctionId/forward/auction',
        name: 'BuyerEditForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/EditAuction.vue')
    },

    // Details Auction
    {
        path: '/buyer/:auctionId/forward/auction',
        name: 'BuyerDetailsForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/DetailsAuction.vue')
    },
    {
        path: '/company/:auctionId/technical/forward/auction',
        name: 'BuyerDetailsForwardAuctionTechnical',
        component: () => import('../../views/company/auction/foward_auctions/TechnicalDetails.vue')
    },
    {
        path: '/buyer/:auctionId/reverse/auction',
        name: 'BuyerDetailsReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/DetailsAuction.vue')
    },
    {
        path: '/company/:auctionId/technical/reverse/auction',
        name: 'BuyerDetailsReverseAuctionTechnical',
        component: () => import('../../views/company/auction/reverse_auctions/TechnicalDetails.vue')
    },

    // Invited Participants
    {
        path: '/buyer/invited/suppliers/:auctionId/reverse/auction',
        name: 'BuyerInvitedParticipantsReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/invitees/Invitees.vue')
    },
    {
        path: '/buyer/invited/suppliers/:auctionId/forward/auction',
        name: 'BuyerInvitedParticipantsForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/invitees/Invitees.vue')
    },

    // Add Item
    {
        path: '/buyer/reverse/:auctionId/auction/add/item',
        name: 'BuyerReverseAuctionAddItem',
        component: () => import('../../views/company/auction/reverse_auctions/item/AddItem.vue')
    },
    {
        path: '/buyer/forward/:auctionId/auction/add/item',
        name: 'BuyerForwardAuctionAddItem',
        component: () => import('../../views/company/auction/foward_auctions/item/AddItem.vue')
    },

    // Edit Item
    {
        path: '/buyer/reverse/:auctionId/:itemId/auction/edit/item',
        name: 'BuyerReverseAuctionAddItem',
        component: () => import('../../views/company/auction/reverse_auctions/item/EditItem.vue')
    },
    {
        path: '/buyer/forward/:auctionId/:itemId/auction/edit/item',
        name: 'BuyerForwardAuctionAddItem',
        component: () => import('../../views/company/auction/foward_auctions/item/EditItem.vue')
    },

    // Details Item
    {
        path: '/buyer/item/:auctionId/:itemId/forward/auction',
        name: 'BuyerItemDetailsForwardAuction',
        component: () => import('../../views/company/auction/foward_auctions/item/DetailsItem.vue')
    },
    {
        path: '/buyer/item/:auctionId/:itemId/reverse/auction',
        name: 'BuyerItemDetailsReverseAuction',
        component: () => import('../../views/company/auction/reverse_auctions/item/DetailsItem.vue')
    },

    // Download Progress Auction Page
    {
        path: '/company/auction/download/progress/:task_id',
        name: 'companyAuctionDownloadProgress',
        component: () => import('../../views/company/auction/DownloadProgress.vue')
    },

    // Uplaod Multiple Images
    {
        path: '/uploadmultiple',
        name: 'uploadmultiple',
        component: () => import('../../views/company/auction/uploadmultiple')
    },

    // Section
    {
        path: '/company/reverse-auction/create-section/:auctionId',
        name: 'BuyerReverseAuctionSectionCreate',
        component: () => import('../../views/company/auction/reverse_auctions/section/Create.vue')
    },
    {
        path: '/company/reverse-auction/edit-section/:auctionId/:sectionId',
        name: 'BuyerReverseAuctionSectionEdit',
        component: () => import('../../views/company/auction/reverse_auctions/section/Edit.vue')
    },
    {
        path: '/company/reverse-auction/section/questions/:auctionId/:sectionId',
        name: 'BuyerReverseAuctionSectionQuestions',
        component: () => import('../../views/company/auction/reverse_auctions/section/Questions.vue')
    },
    {
        path: '/company/reverse-auction/create-question/:auctionId/:sectionId',
        name: 'BuyerReverseAuctionQuestionCreate',
        component: () => import('../../views/company/auction/reverse_auctions/section/CreateQuestion.vue')
    },
    {
        path: '/company/reverse-auction/edit-question/:auctionId/:sectionId/:questionId',
        name: 'BuyerReverseAuctionQuestionEdit',
        component: () => import('../../views/company/auction/reverse_auctions/section/EditQuestion.vue')
    },
    {
        path: '/company/reverse-auction/question-upload-progress/:auctionId/:taskId',
        name: 'BuyerReverseAuctionQuestionUploadProgress',
        component: () => import('../../views/company/auction/reverse_auctions/section/QuestionUploadProgress.vue')
    },
    {
        path: '/company/reverse-auction/reports/:auctionId',
        name: 'BuyerReverseAuctionReports',
        component: () => import('../../views/company/auction/reverse_auctions/reports/Details.vue')
    },
    {
        path: '/company/reverse-auction/report/progress/:taskId',
        name: 'BuyerReverseAuctionReportProgress',
        component: () => import('../../views/company/auction/reverse_auctions/reports/Progress.vue')
    },
    // Forward auction
    {
        path: '/company/forward-auction/create-section/:auctionId',
        name: 'BuyerForwardAuctionSectionCreate',
        component: () => import('../../views/company/auction/foward_auctions/section/Create.vue')
    },
    {
        path: '/company/forward-auction/edit-section/:auctionId/:sectionId',
        name: 'BuyerForwardAuctionSectionEdit',
        component: () => import('../../views/company/auction/foward_auctions/section/Edit.vue')
    },
    {
        path: '/company/forward-auction/section/questions/:auctionId/:sectionId',
        name: 'BuyerForwardAuctionSectionQuestions',
        component: () => import('../../views/company/auction/foward_auctions/section/Questions.vue')
    },
    {
        path: '/company/forward-auction/create-question/:auctionId/:sectionId',
        name: 'BuyerForwardAuctionQuestionCreate',
        component: () => import('../../views/company/auction/foward_auctions/section/CreateQuestion.vue')
    },
    {
        path: '/company/forward-auction/edit-question/:auctionId/:sectionId/:questionId',
        name: 'BuyerForwardAuctionQuestionEdit',
        component: () => import('../../views/company/auction/foward_auctions/section/EditQuestion.vue')
    },
    {
        path: '/company/forward-auction/question-upload-progress/:auctionId/:taskId',
        name: 'BuyerForwardAuctionQuestionUploadProgress',
        component: () => import('../../views/company/auction/foward_auctions/section/QuestionUploadProgress.vue')
    },
    {
        path: '/company/forward-auction/reports/:auctionId',
        name: 'BuyerForwardAuctionReports',
        component: () => import('../../views/company/auction/foward_auctions/reports/Details.vue')
    },
    {
        path: '/company/forward-auction/report/progress/:taskId',
        name: 'BuyerForwardAuctionReportProgress',
        component: () => import('../../views/company/auction/foward_auctions/reports/Progress.vue')
    },
]

export default routes;